<template>
  <div>
    <hr
      class="hr-text"
      data-content="Danh mục sản phẩm"
      style="font-weight: 600"
    />
    <Categories
      @category-selected="handleCategorySelected"
      :categories="stockCheckSheet.categories"
    />
    <hr class="hr-text" :data-content="dataContent" style="font-weight: 600" />
    <div class="mb-5">
      <b-row class="mb-3">
        <b-col md="3">
          <b-form-select
            v-model="selectTypeSearch"
            class="select-style"
            :options="listTypeSearch"
            size="sm"
            value-field="id"
            text-field="name"
            @change="changePlaceholder"
          ></b-form-select>
        </b-col>
        <b-col md="8">
          <b-form-input
            size="sm"
            v-model="textSearchProduct"
            @input="onInputChangeProduct"
            class="autosuggest__input"
            @keyup.enter="fetchProductByCategory()"
            :placeholder="inputPlaceHolder"
          >
            <template slot-scope="{ suggestion }">
              <div>{{ suggestion.item.productName }}</div>
            </template>
          </b-form-input>
        </b-col>
        <b-col>
          <span
            type="button"
            v-b-tooltip.hover.right="'Danh sách sản phẩm đã kiểm kê'"
          >
            <i
              v-if="selectedCategoryId"
              @click="toggleClickButtonCart()"
              class="fa-solid fa-cart-shopping btn-cart"
              :class="isCheckShoppingCart ? 'text-success' : 'text-muted'"
            >
            </i>
          </span>
        </b-col>
      </b-row>
    </div>
    <b-row class="mb-5">
      <b-col>
        <button
          v-if="isCheckShoppingCart"
          @click="backToProductByCate()"
          class="btn mr-3 btn-secondary font-weight-bold btn-sm"
        >
          <i class="fa-solid fa-chevron-left"></i>Trở về
        </button>
      </b-col>
      <b-col>
        <div class="d-flex d-inline justify-content-end mb-4">
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-dark"></i>
            Tồn có thể bán
          </span>
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-warning"></i>
            Đang giữ hàng
          </span>
          <span class="mr-2">
            <i class="fas fa-circle fa-sm text-primary"></i>
            Đang bảo hành
          </span>
        </div>
      </b-col>
    </b-row>
    <b-table
      ref="top"
      :items="items"
      :fields="fieldsComputed"
      :per-page="isCheckShoppingCart ? perPage : undefined"
      :current-page="isCheckShoppingCart ? currentPage : undefined"
      :busy="isBusy"
    >
      <template v-if="isCheckShoppingCart" v-slot:cell(no)="data">{{
        data.item.index
      }}</template>
      <template v-else v-slot:cell(no)="data">{{
        setIndexTable(data.item.index)
      }}</template>
      <template v-slot:cell(barCode)="data">
        <div v-if="data.item.barCode !== ''">
          <b v-text="data.item.barCode"></b>
          <p class="productCode m-0">
            <span v-text="data.item.productName"></span>
          </p>
        </div>
      </template>
      <template v-slot:cell(imei)="data">
        <b-form-textarea
          v-if="data.item.productType === PRODUCT_TYPE.PRODUCT_IMEI"
          :disabled="data.item.productType !== PRODUCT_TYPE.PRODUCT_IMEI"
          style="border-color: #007bff"
          size="sm"
          v-model="data.item.realImeiNos"
          @keyup="enterClicked(data.index, data.item.realImeiNos)"
          class="w-100 h-90"
        ></b-form-textarea>
      </template>
      <template v-slot:cell(stockInfo)="data">
        <div
          style="text-align: center"
          v-if="checkPermissions(['STOCK_CHECK_VIEW_DETAIL'])"
        >
          <b
            v-text="data.item.inStockQuantity"
            class="mr-1"
            v-b-tooltip.hover.bottom
            title="Tổng tồn có thể bán"
          ></b>
          <b
            class="text-primary mr-1 font-size-setting"
            v-if="data.item.warrantyQuantity > 0"
            v-b-tooltip.hover.bottom
            title="Tổng đang bảo hành"
          >
            {{ '+ ' + data.item.warrantyQuantity }}
          </b>
          <b
            class="text-warning font-size-setting"
            v-if="data.item.holdingQuantity > 0"
            v-b-tooltip.hover.bottom
            title="Tổng đang giữ hàng"
          >
            {{ '+ ' + data.item.holdingQuantity }}
          </b>
        </div>
      </template>
      <template v-slot:cell(realQuantity)="data">
        <b-form-input
          v-if="!isConfirmed"
          v-model="data.item.realQuantity"
          :disabled="data.item.productType === PRODUCT_TYPE.PRODUCT_IMEI"
          type="number"
          min="0"
        >
        </b-form-input>
        <span v-else>{{ data.item.realQuantity }}</span>
      </template>
      <template v-slot:cell(stateName)="data">
        <span
          :class="checkClassStateName(data.item.stateName)"
          class="font-weight-bold"
        >
          {{ data.item.stateName }}
          <span v-if="data.item.stockInfo">{{ data.item.stockInfo }}</span>
        </span>
      </template>
      <template v-slot:cell(imeiNos)="data">
        <span
          v-for="imeiNo in data.item.imeiNos"
          :key="imeiNo"
          :class="getClassForImei(imeiNo, data.item.realImeiNos)"
        >
          {{ imeiNo }}
          <br />
        </span>
      </template>
      <template v-slot:cell(status)="data">
        <div
          @click="upsertStockCheckProduct([data.item])"
          v-b-tooltip.hover
          title="Thêm sản phẩm"
          v-if="!isConfirmed && !isCheckShoppingCart"
        >
          <i class="fa fa-plus cursor-pointer icon-style"></i>
        </div>
        <div
          @click="updateStockCheckProduct([data.item])"
          v-b-tooltip.hover
          title="Cập nhật"
          v-if="isCheckShoppingCart && !isConfirmed"
        >
          <i class="fa-solid fa-check cursor-pointer icon-style"></i>
        </div>
      </template>
    </b-table>
    <b-row class="d-flex justity-content-center">
      <b-col v-if="!isCheckShoppingCart">
        <p class="mt-3 text-dark" style="font-weight: 500">
          Tổng số :
          {{ totalItem }}
        </p>
      </b-col>
      <b-col v-else>
        <p class="mt-3 text-dark" style="font-weight: 500">
          Tổng số :
          {{ this.itemLength }}
        </p>
      </b-col>
      <b-pagination
        v-if="isCheckShoppingCart"
        class="custom-pagination"
        v-show="totalItem > 10"
        v-model="currentPage"
        :total-rows="totalItem"
        :per-page="perPage"
        aria-controls="my-table"
      >
      </b-pagination>
      <b-pagination-nav
        v-else
        class="custom-pagination"
        v-show="numberOfPage >= 2"
        :link-gen="linkGen"
        :number-of-pages="numberOfPage"
        use-router
        @change="fetchProductByCategory()"
        align="right"
        first-class="page-item-first btn btn-icon btn-sm mr-1 my-1"
        prev-class="page-item-prev btn btn-icon btn-sm mr-.5 my-1"
        next-class="page-item-next btn btn-icon btn-sm mr-1 my-1 ml-.5"
        last-class="page-item-last btn btn-icon btn-sm my-1 "
        page-class="btn btn-icon btn-sm border-0 mr-.5 my-1"
      >
        <template v-slot:first-text>
          <span>
            <i class="ki ki-bold-double-arrow-back icon-xs"></i>
          </span>
        </template>

        <template v-slot:prev-text>
          <i class="ki ki-bold-arrow-back icon-xs"></i>
        </template>

        <template v-slot:next-text>
          <i class="ki ki-bold-arrow-next icon-xs"></i>
        </template>

        <template v-slot:last-text>
          <span class="text-info">
            <i class="ki ki-bold-double-arrow-next icon-xs"></i>
          </span>
        </template>
      </b-pagination-nav>
    </b-row>
    <hr />
    <div>
      <div>
        <b-button
          v-if="isCheckShoppingCart && itemLength && !isConfirmed"
          @click="updateStockCheckProduct(items)"
          size="sm"
          variant="primary"
          class="font-weight-bolder mr-3"
        >
          Xác nhận
        </b-button>
        <slot> </slot>
      </div>
    </div>
  </div>
</template>

<script>
import {
  STOCK_CHECK_SHEET_STATUS,
  PRODUCT_SEARCH_TYPE,
  PRODUCT_TYPE,
} from '@/utils/enum';
import ApiService from '@/core/services/api.service';
import { TIME_TRIGGER } from '@/utils/constants';
import Categories from '@/view/components/inventoryCheck/Categories.vue';
import { makeToastSuccess } from '@/utils/common';
import { checkPermissions, checkPermission } from '@/utils/saveDataToLocal';
import debounce from 'lodash/debounce';
import { handleError } from '../../../utils/common';
export default {
  props: {
    stockCheckSheetData: {
      type: Object,
    },
  },
  watch: {
    $props: {
      handler(newVal) {
        this.stockCheckSheet = newVal.stockCheckSheetData;
        this.isConfirmed =
          this.stockCheckSheet.status !== STOCK_CHECK_SHEET_STATUS.NEW;
        this.selectedCategoryId = this.stockCheckSheet.categories[0].id;
        if (this.$route.query?.productInCart) {
          this.getListProductInCart();
          return;
        }
        this.fetchProductByCategory();
        this.handleSearchType(this.stockCheckSheet.productType);
        this.configureFieldsForPermission();
      },
      deep: true,
      immediate: false,
    },
  },
  data() {
    return {
      PRODUCT_TYPE,
      STOCK_CHECK_SHEET_STATUS,
      PRODUCT_SEARCH_TYPE,
      items: [],
      textSearchProduct: '',
      inputPlaceHolder: 'Tìm kiếm theo tên, mã, mã vạch sản phẩm',
      dataContent: 'Danh sách sản phẩm',
      isCheckShoppingCart: false,
      selectTypeSearch: PRODUCT_SEARCH_TYPE.PRODUCT_NAME,
      productInCart: this.$route.query?.productInCart,
      linkGen: (pageNum) => {
        return pageNum === 1
          ? `?id=${this.stockCheckSheet.id}`
          : `?id=${this.stockCheckSheet.id}&page=${pageNum}`;
      },
      listTypeSearch: [
        {
          id: PRODUCT_SEARCH_TYPE.PRODUCT_NAME,
          name: 'Tìm sản phẩm',
        },
        {
          id: PRODUCT_SEARCH_TYPE.IMEI_CODE,
          name: 'Tìm sản phẩm IMEI',
        },
      ],
      totalItem: 0,
      limit: 10,
      numberOfPage: 1,
      currentPage: this.$route.query.page || 1,
      perPage: 10,
      stockCheckSheet: { status: 1, categories: [] }, // default
      searchProduct: '',
      searchProductImei: '',
      fields: [
        {
          key: 'no',
          label: 'STT',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
          thStyle: { width: '5%' },
        },
        {
          key: 'barCode',
          label: 'Sản phẩm',
          sortable: false,
          thStyle: { width: '30%' },
          tdClass: 'text-left align-middle',
          thClass: 'text-center align-middle',
        },
        {
          key: 'imei',
          label: 'IMEI',
          sortable: false,
          thStyle: { width: '20%' },
          tdClass: 'text-left align-middle',
          thClass: 'text-center align-middle',
        },
        {
          key: 'imeiNos',
          label: 'IMEI hệ thống',
          sortable: false,
          thStyle: { width: '15%' },
          tdClass: 'text-left align-middle',
          thClass: 'text-center align-middle',
        },
        {
          key: 'stockInfo',
          label: 'Tồn kho',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
          thStyle: { width: '10%' },
        },
        {
          key: 'realQuantity',
          label: 'Số lượng',
          thClass: 'text-center align-middle',
          tdClass: 'text-right align-middle',
          thStyle: { width: '10%' },
        },
        {
          key: 'stateName',
          label: 'Tình trạng',
          thClass: 'text-center align-middle',
          thStyle: { width: '10%' },
          tdStyle: {
            fontSize: '1.5rem',
          },
        },
        {
          key: 'status',
          label: '',
          tdClass: 'text-center align-middle',
          thClass: 'text-center align-middle',
        },
      ],
      selectedCategoryId: null,
      isBusy: false,
      isConfirmed: true,
    };
  },
  methods: {
    checkPermissions,
    checkPermission,
    handleSearchType(productType) {
      if (
        [PRODUCT_TYPE.PRODUCT_IMEI, PRODUCT_TYPE.PRODUCT].includes(productType)
      ) {
        this.listTypeSearch = this.listTypeSearch.filter(
          (searchType) => searchType.id === productType,
        );
        this.selectTypeSearch = productType;
      }
      this.changePlaceholder();
    },
    //TODO
    configureFieldsForPermission() {
      const hasPermissionViewDetail = checkPermission(
        'STOCK_CHECK_VIEW_DETAIL',
      );
      if (
        !hasPermissionViewDetail ||
        this.stockCheckSheetData.productType !== PRODUCT_TYPE.PRODUCT_IMEI
      ) {
        this.fields = this.fields.filter((field) => field.key !== 'imeiNos');
      }
    },
    enterClicked(index, value) {
      let countLine = 0;
      const tmp = value.split(/\r\n|\r|\n/);
      tmp.forEach((element) => {
        if (element.trim() !== '') {
          countLine++;
        }
      });
      this.items[index].realQuantity = countLine;
    },
    setIndexTable(index) {
      const currentPage = this.$route.query.page || 1;
      return (currentPage - 1) * this.limit + index;
    },
    backToProductByCate() {
      this.$router
        .push({
          name: 'upsert-inventory-check',
          query: { id: this.stockCheckSheet.id },
        })
        .catch(() => {});
      this.fetchProductByCategory();
    },
    checkClassStateName(stateName) {
      if (stateName === 'Đủ') {
        return 'badge badge-success';
      }
      if (stateName === 'Lệch') {
        return 'text-danger';
      }
      if (stateName === 'Mới') {
        return 'badge badge-light';
      }
      if (stateName === 'SP bên ngoài') {
        return 'badge badge-warning';
      }
    },
    mappingStockCheckProductModel: function (products) {
      return products.map((product) => {
        return {
          id: product?.id,
          productId: product.productId,
          stockCheckSheetId: this.stockCheckSheet.id,
          stockCheckCategoryId: this.selectedCategoryId,
          realImeiNos: product.realImeiNos
            .split(/\r\n|\r|\n|,/)
            .map((item) => item.trim()),
          realQuantity: Number(product.realQuantity),
          version: product.version || 0,
          productOutside: product.productOutside,
        };
      });
    },
    async addProductToCart(products) {
      const body = {
        stockCheckProducts: this.mappingStockCheckProductModel(products),
      };
      return ApiService.put('/v2/stock-check-product/add-to-cart', body);
    },
    upsertStockCheckProduct: async function (products) {
      try {
        const response = await this.addProductToCart(products);
        const { message, data } = response.data;
        for (const dataCreated of data) {
          const index = this.items.findIndex(
            (item) => item.id == dataCreated.id,
          );
          this.items[index].version = dataCreated.version;
          this.items[index].realImeiNos = this.convertImeiToString(
            dataCreated.realImeiNos,
          );
          this.items[index].realQuantity = dataCreated.realQuantity;
        }
        makeToastSuccess(message);
      } catch (error) {
        handleError(error);
        const { status } = error.response?.data;
        if (status === 409) {
          this.retryFetchOnConflict();
        }
      }
    },
    async updateStockCheckProduct(product) {
      try {
        const dataUpdate = {
          stockCheckProducts: this.mappingStockCheckProductModel(product),
        };
        const response = await ApiService.put(
          'v2/stock-check-product/confirm',
          dataUpdate,
        );
        const { message } = response?.data;
        makeToastSuccess(message);
        this.getListProductInCart();
      } catch (error) {
        handleError(error);
        const { status } = error.response?.data;
        if (status === 409) {
          this.retryFetchOnConflict();
        }
      }
    },
    retryFetchOnConflict() {
      if (this.$route.query?.productInCart) {
        this.getListProductInCart(this.selectedCategoryId);
        return;
      }
      this.fetchProductByCategory();
    },
    async getListProductInCart() {
      const { productInCart } = this.$route.query;
      if (!productInCart) {
        this.$router
          .push({
            name: 'upsert-inventory-check',
            query: {
              id: this.stockCheckSheet.id,
              productInCart: this.selectedCategoryId,
            },
          })
          .catch(() => {});
        this.currentPage = 1;
      }
      this.currentPage = this.$route.query?.page || 1;
      try {
        this.dataContent = 'Danh sách sản phẩm đã kiểm';
        this.isCheckShoppingCart = true;
        const params = {
          stockCheckCategoryId: this.selectedCategoryId,
          limit: this.limit,
          page: this.currentPage || 1,
        };
        const response = await ApiService.query(
          `/v2/stock-check-product/products-cart`,
          { params },
        );
        this.items = this.mappingStockCheckProductRes(
          response.data.data?.dataset,
        );
        this.totalItem = response.data.data?.totalRow || 0;
        this.numberOfPage = response.data.data?.totalPage || 1;
      } catch (err) {
        handleError(err);
      }
    },
    handleCategorySelected(categoryId) {
      if (this.isBusy) {
        return;
      }
      this.$router
        .push({
          name: 'upsert-inventory-check',
          query: { id: this.stockCheckSheet.id },
        })
        .catch(() => {});
      this.selectedCategoryId = categoryId;
      this.$route.query.page = 1;
      if (this.$route.query?.productInCart) {
        this.getListProductInCart();
        return;
      }
      this.fetchProductByCategory();
    },
    changePlaceholder() {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
        this.searchProductImei = '';
        this.inputPlaceHolder = 'Tìm kiếm theo tên, mã, mã vạch sản phẩm';
      } else if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.IMEI_CODE) {
        this.searchProduct = '';
        this.inputPlaceHolder = 'Tìm kiếm theo mã IMEI sản phẩm';
      }
    },
    handleSearchByProductType() {
      if (this.selectTypeSearch === PRODUCT_SEARCH_TYPE.PRODUCT_NAME) {
        this.searchProduct = this.textSearchProduct;
        this.searchProductImei = '';
      } else {
        this.searchProductImei = this.textSearchProduct;
        this.searchProduct = '';
      }
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'upsert-inventory-check',
      });
      this.fetchProductByCategory();
    },
    getParamsFilter() {
      this.handleSearchByProductType();
      const params = {
        stockCheckCategoryId: this.selectedCategoryId,
        productType: this.stockCheckSheet.productType,
        limit: this.limit,
        page: this.$route.query.page,
        storeId: this.stockCheckSheet.storeId,
        searchImei: this.searchProductImei,
        searchProduct: this.searchProduct,
      };
      return params;
    },
    toggleClickButtonCart() {
      if (this.isCheckShoppingCart) {
        this.$router
          .push({
            name: 'upsert-inventory-check',
            query: {
              id: this.stockCheckSheet.id,
            },
          })
          .catch(() => {});
        this.fetchProductByCategory();
        this.isCheckShoppingCart = false;
      } else {
        this.getListProductInCart();
      }
    },
    fetchProductByCategory: async function () {
      try {
        this.dataContent = 'Danh sách sản phẩm';
        this.isCheckShoppingCart = false;
        this.isBusy = true;
        const params = this.getParamsFilter();
        const response = await ApiService.query('/v2/stock-check-product', {
          params,
        });
        const data = response.data?.data || [];
        this.totalItem = data.totalRow || 0;
        this.numberOfPage = data.totalPage || 1;
        if (
          this.totalItem === 1 &&
          this.selectTypeSearch === PRODUCT_TYPE.PRODUCT_IMEI
        ) {
          const stockCheckProduct = data.dataset[0];
          const foundImei = stockCheckProduct.imeiNos?.find(
            (imeiNo) => imeiNo === this.searchProductImei,
          );
          if (foundImei) {
            const realImeiNos = stockCheckProduct.realImeiNos
              .concat(foundImei)
              .join(', ');
            stockCheckProduct.realImeiNos = realImeiNos;
            const {
              data: { data, message },
            } = await this.addProductToCart([stockCheckProduct]);
            makeToastSuccess(message);
            stockCheckProduct.version = data[0].version;
            stockCheckProduct.realImeiNos = data[0].realImeiNos;
            stockCheckProduct.realQuantity = data[0].realQuantity;
            setTimeout(() => {
              this.textSearchProduct = '';
              this.fetchProductByCategory();
            }, TIME_TRIGGER);
          }
        }
        this.items = this.mappingStockCheckProductRes(data.dataset);
      } catch (err) {
        handleError(err);
      } finally {
        this.isBusy = false;
      }
    },
    onInputChangeProduct() {
      this.debounceInputProduct();
    },
    debounceInputProduct: debounce(function () {
      this.fetchProductByCategory();
    }, TIME_TRIGGER),
    mappingStockCheckProductRes(stockCheckProducts = []) {
      return stockCheckProducts.map((product, index) => {
        return {
          index: index + 1,
          ...product,
          realQuantity: product.realQuantity || 0,
          productType: product.productType,
          createdBy: product.createdBy || '',
          realImeiNos: this.convertImeiToString(product.realImeiNos),
        };
      });
    },
    convertImeiToString(realImeiNos) {
      return realImeiNos ? realImeiNos.join(', \n') : '';
    },
    getClassForImei(imeiNo, realImeiNos) {
      return realImeiNos.includes(imeiNo) ? 'text-success' : 'text-danger';
    },
  },
  components: {
    Categories,
  },
  computed: {
    fieldsComputed() {
      return this.fields;
    },
    itemLength() {
      return this.items.length;
    },
  },
};
</script>

<style scoped>
.scrollable-cell {
  height: 100px;
  overflow-y: auto;
}

.item {
  padding: 5px;
  border-bottom: 1px solid #ccc;
}

.btn-cart {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  font-size: 30px;
}

.cursor-pointer {
  cursor: pointer;
}

.icon-style {
  font-size: 22px;
  color: #3699ff;
}
</style>
